import { localDataUser, localDataApp, localDataDic } from '@iebdc/utils/local-data';

// appId，appKey 数据 Object
export const IEBDC_APP_DATA = localDataApp.get() || {};
IEBDC_APP_DATA.appKey = window.CONFIG.APP_IEBDC_KEY;

// 用户信息 Object
export const IEBDC_USER_DATA = () => {
  return localDataUser.get() || {};
};

// 字典信息
export const IEBDC_DIC_DATA = localDataDic.get() || {};
