<template>
  <div class="readBox">
    <div class="main box-shadow-default">
      <h2 class="text-center weight">{{ title }}不动产登记综合服务平台申请须知及协议</h2>
      <p>
        欢迎使用{{
          title
        }}不动产登记综合服务平台。为方便公众申请不动产登记，现开通服务大厅功能。申请人在申请前须仔细阅读本须知。
      </p>
      <p>一、服务大厅采用实名制，网上申请人应是不动产权利人或权利人依法授权的代理人，并具备完全民事行为能力。</p>
      <p>
        二、网上申请人应认真如实填写有关申请信息，所申请事项及上传的影像材料务必真实、合法、有效。申请人提供虚假材料、虚假信息的，将列入诚信黑名单，并承担相应法律责任。
      </p>
      <p>
        三、服务大厅为预申请，申请人外网提交后，不动产登记机构进行预审核。在核验前若有任意一方申请人提出撤回预申请，可通过系统网上撤回该网上预申请。因撤回网上预申请产生的纠纷由撤回的申请人负责。
      </p>
      <p>
        四、申请人需一致同意不动产登记部门在完成线上受理后以短信方式及时告知申请人受理结果；申请人也可以通过{{
          title
        }}不动产登记综合服务平台个人中心“我的申请”功能中，实时查看或下载电子《不动产登记受理通知书》、《不动产登记申请书》等电子文件，同时也可以实时查看申请办件的办理进度。
      </p>
      <p class="weight">
        本人承诺：本申请系申请人真实意思的表示，并对所填写的上述内容及提交的申请材料真实性负责，如有不实，愿承担法律责任。
      </p>
      <div class="margin-large text-center">
        <span>申请人当前所在地点：</span>
        <el-cascader size="medium" :options="options" v-model="selectedOptions" @change="handleChange"></el-cascader>
      </div>
      <div class="margin-large text-center">
        <el-checkbox v-model="isChecked">我已阅读以上须知并同意</el-checkbox>
      </div>
      <div class="text-center margin-large">
        <el-button type="primary" size="small" :disabled="!isChecked" @click="next"> 下一步 </el-button>
      </div>
    </div>
  </div>
</template>
<script src="http://pv.sohu.com/cityjson?ie=utf-8"></script>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { localDataUser } from '@iebdc/utils/local-data';
import applicantSaveService from '@/pages/iebdc/api/apply/applicant-save';
import { IEBDC_USER_DATA } from '@iebdc/config/app-data';
import { getThirdFlow } from 'iebdc/api/guidance.js';
import request from '@iebdc/utils/request';
import { provinceAndCityData, CodeToText } from 'element-china-area-data';
export default {
  name: 'read',
  computed: {
    // 下一步跳转所需数据
    ...mapState('wysq-data', ['firstFlow']),
    ...mapState('wysq-step', ['nextItem']),
    ...mapState('wysq-step', ['activeIndex', 'currentSelecte']),
    ...mapGetters(['selectedOrganizationsCode']),
    ...mapState('taxStatus', ['taxStatus', 'beianStatus']),

    // 选择的机构信息
    getSelectedOrgInfo() {
      return this.$store.getters['dashboard/getSelectedOrgInfo'];
    },
  },
  data() {
    return {
      title: window.CONFIG.title,
      isChecked: false,
      areaname: '',
      options: provinceAndCityData,
      selectedOptions: [],
      sqrqydm: '',
      sqrip: '',
      sqrszd: '',
    };
  },
  mounted() {
    console.log('[read] [this.firstFlow]', this.firstFlow);
    if (this.firstFlow.thirdTypeData) {
      let currentSelectedFlowCode = this.firstFlow.thirdTypeData.netFlowdefCode;
      let currentSelectedName = this.firstFlow.fourTypeData.name;
      this.$store.commit('wysq-step/getStepList', {
        code: currentSelectedFlowCode,
        currentSelectedName,
      });
    }
    if (this.beianStatus === '1') {
      this.currentSelecte.forEach((data, index) => {
        if (data.name === 'authorizedCertify') this.currentSelecte.splice(index, 1);
      });
    }
    // 跳转到阅读须知
    this.targetToStep('阅读须知');
    this.showCityInfo();
  },
  methods: {
    ...mapMutations('wysq-data', ['setFirstFlow']),
    ...mapMutations('wysq-step', ['targetToStep', 'getStepList']),
    showCityInfo() {
      //实例化城市查询类
      var citysearch = new AMap.CitySearch();
      let $this = this;
      //自动获取用户IP，返回当前城市
      citysearch.getCityByIp(returnCitySN.cip, function (status, result) {
        // let logs = '根据IP定位当前位置为' + result.province + result.city;
        // adcode
        $this.areaname = result.province;
        let areacode = result.adcode;
        let shengcode = areacode.substr(0, 2) + '0000';
        if (areacode.indexOf('0000') !== -1) {
          shengcode = areacode;
          areacode = areacode.substr(0, 2) + '0100';
        }
        $this.selectedOptions = [shengcode, areacode];
        $this.handleChange();
      });
    },
    handleChange() {
      var loc = '';
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]];
      }
      this.sqrqydm = this.selectedOptions[this.selectedOptions.length - 1];
      this.sqrszd = loc;
      this.sqrip = returnCitySN.cip;
    },
    next() {
      if (this.sqrqydm === '' || this.sqrszd === '') {
        this.$message.warning('定位失败，请手动选择申请人所在地');
      } else {
        if (this.firstFlow.thirdTypeData) {
          //当前选择的流程code
          let currentSelectedFlowCode = this.firstFlow.thirdTypeData.netFlowdefCode;
          let currentSelectedName = this.firstFlow.fourTypeData.name;
          //找到code值属于产权核验步骤/提取楼盘表步骤，并渲染相应的步骤条DOM
          //this.$store.commit('wysq-step/getStepList', currentSelectedFlowCode);
          this.$store.commit('wysq-step/getStepList', {
            code: currentSelectedFlowCode,
            currentSelectedName,
          });
        }
        //解决步骤条多了一步，nextItem没有改变问题
        this.$store.commit('wysq-step/targetToStep', '阅读须知');
        if (this.nextItem.title !== '填写申请表') {
          this.$router.push({
            name: this.nextItem.name,
          });
        } else {
          // 不走产权核验
          this.toRegister();
        }
      }
    },
    // 在线登记, 处理需要保存给后台的数据
    async toRegister() {
      /**
       * params 需要提交给后台的数据
       * localDataUser 用户信息
       * firstFlow 选择的申请类型数据
       * firstFlow.secondTypeData 选择的申请类型二级类
       * firstFlow.thirdTypeData 选择的申请类型三级类
       * firstFlow.fourTypeData 选择的申请类型四级类
       * */
      const params = {
        sqrqydm: this.sqrqydm,
        sqrip: this.sqrip,
        sqrszd: this.sqrszd,
        sfyddbl: 0,
        userName: localDataUser.get().realName,
        lcdm: this.firstFlow.thirdTypeData.netFlowdefCode,
        userId: localDataUser.get().id, // 用户id
        sqrlx: localDataUser.get().userCategory, // 申请人类型
        bdclb: this.firstFlow.secondTypeData.bdclbEnum, // 不动产类别
        flowcode: this.firstFlow.thirdTypeData.flowCode,
        netFlowdefCode: this.firstFlow.thirdTypeData.netFlowdefCode,
        flowname: this.firstFlow.thirdTypeData.name,
        djdl: this.firstFlow.thirdTypeData.djdl,
        qllx: this.firstFlow.thirdTypeData.qllx,
        subFlowcode: this.firstFlow.fourTypeData.code,
        subFlowname: this.firstFlow.fourTypeData.name,
        taskCode: this.firstFlow.thirdTypeData.taskCode,
        pjlcmc: this.firstFlow.thirdTypeData.pjlcmc,
        taskHandleItem: this.firstFlow.thirdTypeData.taskHandleItem,
        qlxxExMhList: [],
        qlxz: '', // 补充的参数
        ...this.getSelectedOrgInfo,
      };
      const { data, success, message } = await applicantSaveService.saveSqxx(params);
      if (success) {
        await this.$router.push({
          name: this.nextItem.name,
          params: { ywh: data.wwywh },
        });
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
@import 'src/styles/public';

.main {
  .weight {
    font-weight: bold;
  }
  padding: 20px 120px;
  line-height: 40px;
  letter-spacing: 1px;
  border-radius: $border-radius-base;
  background-color: $background-color-layout;
  p {
    margin: 0 0 10px;
    text-align: justify;
    text-justify: inter-ideograph;
    &.weight {
      @extend .weight;
      color: rgba(85, 85, 85, 1);
    }
  }
  /deep/ .el-checkbox {
    color: $color-black;
    font-weight: bold;
  }
}

.readBox /deep/ {
  .el-select {
    width: 100%;
    margin-bottom: 30px;
  }
  .el-input--small .el-input__inner {
    height: 42px;
    line-height: 42px;
  }
}
</style>
